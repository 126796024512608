import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Empty, Icon } from "antd"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="home_wrapper">
      <div className="home_content">
        <div className="home_details">
          <div className="name">
            <Empty description={<span>404 - Page Not Found</span>} />
            <Link to="/" style={{ textAlign: "center" }}>
              <h4 style={{ marginTop: 50 }}>
                <Icon type="rollback" />
                Go Back
              </h4>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
